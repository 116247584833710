<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" offset-md="4" style="padding-top: 230px;">
        <div>
          <v-text-field label="Usuario" v-model="form.usuario" />
        </div>
        <div>
          <v-text-field type="password" label="Senha" v-model="form.senha" />
        </div>
        <div>
          <v-btn @click="login()" color="success">Entrar</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "../../api";

export default {
  data: () => ({
    form: {
      usuario: "",
      senha: ""
    }
  }),

  created() {
    if (localStorage.getItem("token")) {
      window.location = "/#/admin";
    }
  },

  methods: {
    login() {
      api
        .post("?route=login", this.form)
        .then(response => {
          localStorage.setItem("token", response.data.token);
          window.location = "/#/admin";
        })
        .catch(() => {
          // TODO: Modal bonito
          alert("Usuario ou senha inválidos.");
        });
    }
  },

  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "noindex"
      }
    ]
  }
};
</script>
